// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-all-on-4-js": () => import("./../src/pages/blog/all-on-4.js" /* webpackChunkName: "component---src-pages-blog-all-on-4-js" */),
  "component---src-pages-blog-discolored-tooth-js": () => import("./../src/pages/blog/discolored-tooth.js" /* webpackChunkName: "component---src-pages-blog-discolored-tooth-js" */),
  "component---src-pages-blog-impact-food-js": () => import("./../src/pages/blog/impact-food.js" /* webpackChunkName: "component---src-pages-blog-impact-food-js" */),
  "component---src-pages-blog-licowki-js": () => import("./../src/pages/blog/licowki.js" /* webpackChunkName: "component---src-pages-blog-licowki-js" */),
  "component---src-pages-cennik-js": () => import("./../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-uslugi-js": () => import("./../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-pages-uslugi-implantologia-js": () => import("./../src/pages/uslugi/implantologia.js" /* webpackChunkName: "component---src-pages-uslugi-implantologia-js" */),
  "component---src-pages-uslugi-kompleksowa-opieka-js": () => import("./../src/pages/uslugi/kompleksowa-opieka.js" /* webpackChunkName: "component---src-pages-uslugi-kompleksowa-opieka-js" */),
  "component---src-pages-uslugi-medycyna-estetyczna-js": () => import("./../src/pages/uslugi/medycyna-estetyczna.js" /* webpackChunkName: "component---src-pages-uslugi-medycyna-estetyczna-js" */),
  "component---src-pages-uslugi-stomatologia-estetyczna-js": () => import("./../src/pages/uslugi/stomatologia-estetyczna.js" /* webpackChunkName: "component---src-pages-uslugi-stomatologia-estetyczna-js" */),
  "component---src-pages-zespol-js": () => import("./../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-pages-zespol-dorota-maliszewska-js": () => import("./../src/pages/zespol/dorota-maliszewska.js" /* webpackChunkName: "component---src-pages-zespol-dorota-maliszewska-js" */),
  "component---src-pages-zespol-elwira-zawislak-js": () => import("./../src/pages/zespol/elwira-zawislak.js" /* webpackChunkName: "component---src-pages-zespol-elwira-zawislak-js" */),
  "component---src-pages-zespol-karolina-krempa-js": () => import("./../src/pages/zespol/karolina-krempa.js" /* webpackChunkName: "component---src-pages-zespol-karolina-krempa-js" */),
  "component---src-pages-zespol-monika-bochenek-js": () => import("./../src/pages/zespol/monika-bochenek.js" /* webpackChunkName: "component---src-pages-zespol-monika-bochenek-js" */),
  "component---src-pages-zespol-paulina-gesich-js": () => import("./../src/pages/zespol/paulina-gesich.js" /* webpackChunkName: "component---src-pages-zespol-paulina-gesich-js" */),
  "component---src-pages-zespol-radoslaw-lysek-js": () => import("./../src/pages/zespol/radoslaw-lysek.js" /* webpackChunkName: "component---src-pages-zespol-radoslaw-lysek-js" */)
}

